<template>
    <div class="content-wrapper">
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <div class="d-flex justify-center align-center" v-if="pageLoading">
            <div>
                <div style="width: 100%; display: flex; justify-content: center">
                    <v-progress-circular indeterminate color="main"> </v-progress-circular>
                </div>
                <div class="mt-6">
                    <span>Отчет может формироваться около 1 минуты</span>
                </div>
            </div>
        </div>
        <div class="content" v-show="!pageLoading">
            <v-row justify="start" align="center" class="mb-7 mx-0">
                <h1 class="dashboard">Обзор удержания за период</h1></v-row
            >

            <div>
                <div v-if="!billingEnabled || (billingEnabled && currentTariff)" class="filters">
                    <CustomActivatorSelect
                        :items="filtersLists.groupList"
                        @input="setFilterValue('groupList', $event)"
                        :value="displayFilters.groupList"
                        :transition="false"
                        :multiple="true"
                    >
                        <template v-slot:activator="{ selected, _id }">
                            <BaseInputOutlined
                                label="Группа услуг"
                                :hide-details="true"
                                persistent-label
                                class="filter"
                                append-icon="mdi-menu-down"
                                :id="_id"
                                :value="`Выбрано ${selected.length}`"
                                readonly
                            >
                            </BaseInputOutlined>
                        </template>
                    </CustomActivatorSelect>
                    <CustomActivatorSelect
                        :items="filtersLists.serviceList"
                        @input="setFilterValue('serviceList', $event)"
                        :value="displayFilters.serviceList"
                        :transition="false"
                        :multiple="true"
                    >
                        <template v-slot:activator="{ selected, _id }">
                            <BaseInputOutlined
                                label="Услуги"
                                :hide-details="true"
                                persistent-label
                                class="filter"
                                append-icon="mdi-menu-down"
                                :id="_id"
                                :value="`Выбрано ${selected.length}`"
                                readonly
                            >
                            </BaseInputOutlined>
                        </template>
                    </CustomActivatorSelect>
                    <CustomActivatorSelect
                        :items="filtersLists.staffList"
                        @input="setFilterValue('staffList', $event)"
                        :value="displayFilters.staffList"
                        :transition="false"
                        :multiple="true"
                    >
                        <template v-slot:activator="{ selected, _id }">
                            <BaseInputOutlined
                                label="Сотрудники"
                                :hide-details="true"
                                persistent-label
                                class="filter"
                                append-icon="mdi-menu-down"
                                :id="_id"
                                :value="`Выбрано ${selected.length}`"
                                readonly
                            >
                            </BaseInputOutlined>
                        </template>
                    </CustomActivatorSelect>
                    <CustomActivatorSelect
                        :items="filtersLists.productsDisplay"
                        v-model="filters.productsDisplay"
                        :transition="false"
                    >
                        <template v-slot:activator="{ selected, _id }">
                            <BaseInputOutlined
                                label="Отображать продукты"
                                :hide-details="true"
                                persistent-label
                                class="filter"
                                append-icon="mdi-menu-down"
                                :id="_id"
                                :value="selected ? selected.name : ''"
                                readonly
                            >
                            </BaseInputOutlined>
                        </template>
                    </CustomActivatorSelect>
                    <SliderInput
                        :value="[filters.returnRateFrom, filters.returnRateTo]"
                        :min="0"
                        :max="100"
                        @input="
                            filters.returnRateFrom = $event[0]
                            filters.returnRateTo = $event[1]
                        "
                    >
                        <template v-slot:activator="{ value, _id }">
                            <BaseInputOutlined
                                label="Удержание"
                                :hide-details="true"
                                persistent-label
                                class="filter"
                                append-icon="mdi-menu-down"
                                :id="_id"
                                :value="`${value[0]} - ${value[1]}`"
                                readonly
                            >
                            </BaseInputOutlined>
                        </template>
                    </SliderInput>
                    <v-menu
                        ref="datesMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <BaseInputOutlined
                                class="filter"
                                color="main"
                                v-model="dateRangeText"
                                label="Дата посещения"
                                persistent-label
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                :hide-details="true"
                                v-bind="attrs"
                                v-on="on"
                            ></BaseInputOutlined>
                        </template>
                        <v-date-picker v-model="dates" no-title scrollable range color="main">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dateMenu = false"> Отмена </v-btn>
                        </v-date-picker>
                    </v-menu>
                </div>
                <DemoSubView v-else :backroundLink="require('@/assets/img/project/expiredTariff/overview-of-retention-for-the-period.png')"></DemoSubView>
                <v-switch
                    hide-details
                    v-model="filters.sameStaff"
                    label="Учитывать возвращение только к тому же сотруднику"
                    :false-value="0"
                    :true-value="1"
                ></v-switch>
                <v-switch
                    v-if="isNetwork"
                    hide-details
                    v-model="filters.sameFilial"
                    label="Учитывать возвращение только к в тот же филиал"
                    :false-value="0"
                    :true-value="1"
                ></v-switch>
                <v-btn
                    color="main"
                    class="white--text mt-6 mb-4"
                    @click="initData()"
                    :loading="dashboardDataLoading"
                    >Применить</v-btn
                >
                <div v-if="!dashboardDataLoading">
                    <ResultValues
                        class="mb-14"
                        :chartData="first(dashboardData.platesData) || {}"
                    ></ResultValues>
                    <ReturnabilityByStaff
                        :chartData="dashboardData.statByStaffData"
                        :dateFrom="dateBorders.from"
                        :dateTo="dateBorders.to"
                    >
                    </ReturnabilityByStaff>
                    <ReturnabilityDynamic
                        :chartData="dashboardData.statByMonthData"
                        :prevChartData="dashboardData.statByMonthDataPrev"
                        :dateFrom="dateBorders.from"
                        :dateTo="dateBorders.to"
                    ></ReturnabilityDynamic>
                    <ReturnabilityByServiceGroup
                        :chartData="dashboardData.statByGroupData"
                        :dateFrom="dateBorders.from"
                        :dateTo="dateBorders.to"
                    >
                    </ReturnabilityByServiceGroup>
                    <ReturnabilityByService
                        :chartData="dashboardData.statByServiceData"
                        :dateFrom="dateBorders.from"
                        :dateTo="dateBorders.to"
                    ></ReturnabilityByService>
                    <DidntReturnVisits :chartData="dashboardData.statLastVisitData"></DidntReturnVisits>
                </div>
                <SpinnerLoader v-else />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import ResultValues from '@/components/chisai/GCB2/returnability/ResultValues.vue'
import ReturnabilityByService from '@/components/chisai/GCB2/returnability/charts/ReturnabilityByService.vue'
import DidntReturnVisits from '@/components/chisai/GCB2/returnability/charts/DidntReturnVisits.vue'
import ReturnabilityByServiceGroup from '@/components/chisai/GCB2/returnability/charts/ReturnabilityByServiceGroup.vue'
import ReturnabilityByStaff from '@/components/chisai/GCB2/returnability/charts/ReturnabilityByStaff.vue'
import ReturnabilityDynamic from '@/components/chisai/GCB2/returnability/charts/ReturnabilityDynamic.vue'

import SliderInput from '@root/src/components/ui/SliderInput.vue'
import { getReturnabilityData } from '@root/src/api/chisai/GCB2'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import CustomActivatorSelect from '@root/src/components/inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '@root/src/components/inputs/BaseInputOutlined.vue'
import { getMinDate, getMaxDate } from '@root/src/utils'
import api from '@root/src/api/api'
import DemoSubView from './subViews/DemoSubView.vue'
import { useProject } from '@root/src/hooks/useProject'
import { useBillingStore } from '@root/src/store/stores/billing'

export default {
    name: 'GCB2ReturnabilityDashboard',
    components: {
        DemoSubView,
        Breadcrumbs,
        CustomActivatorSelect,
        SliderInput,
        BaseInputOutlined,
        ResultValues,
        ReturnabilityByService,
        ReturnabilityByStaff,
        ReturnabilityByServiceGroup,
        ReturnabilityDynamic,
        DidntReturnVisits,
        SpinnerLoader,
    },

    data: () => ({
        projectType: null,
        dashboardDataLoading: true,
        dashboardData: {},
        pageLoading: true,
        DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
        dateMenu: false,
        dates: [],
        filters: {},
    }),
    setup(props, context){
        const { project } = useProject()
        const billingStore = useBillingStore()
        const currentTariff = billingStore.getCurrentTariffState(project.value.id)
        const billingEnabled = project.billingEnabled
        return{
            billingEnabled,
            currentTariff
        }
    },
    computed: {
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        isNetwork() {
            return this.projectType === 'network'
        },
        breadcrumbs() {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: 'HR платформа Точек красоты',
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Обзор удержания клиентов',
                    to: '',
                    disabled: true,
                },
            ]
        },
        dateBorders() {
            return {
                to: moment(getMaxDate(this.dates)).format(this.DEFAULT_DATE_FORMAT),
                from: moment(getMinDate(this.dates)).format(this.DEFAULT_DATE_FORMAT),
            }
        },
        dateRangeText() {
            const dateFormat = 'DD.MM.YYYY'
            const orderedDates = [getMinDate(this.dates), getMaxDate(this.dates)]
            const formatedDates = orderedDates.map(date => moment(date).format(dateFormat))
            return formatedDates.join(' - ')
        },
        displayFilters() {
            return {
                groupList: Object.values(this.filters.groupList ?? {})
                    .filter(el => el.selected)
                    .map(el => el.id),
                serviceList: Object.values(this.filters.serviceList ?? {})
                    .filter(el => el.selected)
                    .map(el => el.id),
                staffList: Object.values(this.filters.staffList ?? {})
                    .filter(el => el.selected)
                    .map(el => el.id),
            }
        },
        filtersLists() {
            return {
                groupList: Object.values(this.filters.groupList ?? {}).map(el => ({
                    id: el.id,
                    name: el.option,
                })),
                serviceList: Object.values(this.filters.serviceList ?? {}).map(el => ({
                    id: el.id,
                    name: el.option,
                })),
                staffList: Object.values(this.filters.staffList ?? {}).map(el => ({
                    id: el.id,
                    name: el.option,
                })),
                productsDisplay: [
                    { id: 0, name: 'Не отображать' },
                    { id: 1, name: 'Отображать' },
                ],
            }
        },
        queryFilters() {
            return _.pickBy(
                {
                    dateFrom: this.dateBorders.from,
                    dateTo: this.dateBorders.to,
                    staffList: this.filters.staffList,
                    groupList: this.filters.groupList,
                    serviceList: this.filters.serviceList,
                    productsDisplay: this.filters.productsDisplay,
                    returnRateFrom: this.filters.returnRateFrom,
                    returnRateTo: this.filters.returnRateTo,
                    sameFilial: this.isNetwork ? this.filters.sameFilial : undefined,
                    sameStaff: this.filters.sameStaff,
                },
                (v, k) => {
                    if (_.isObject(v) && _.isEmpty(v)) {
                        return false
                    }
                    if (
                        !_.isNil(v) &&
                        this.displayFilters[k] &&
                        (this.filtersLists[k].length === this.displayFilters[k].length ||
                            this.displayFilters[k].length === 0)
                    ) {
                        return false
                    }
                    return true
                }
            )
        },
    },

    methods: {
        first: _.first,
        setDefaultDateBorders() {
            this.dates = [
                moment()
                    .subtract(425, 'days')
                    .toISOString(),
                moment()
                    .subtract(90, 'days')
                    .toISOString(),
            ]
        },

        setFilterValue(filterId, val) {
            const updatedFilterVal = {}
            const selectedHmap = val.reduce((a, c) => Object.assign(a, { [c]: true }), {})
            this.filtersLists[filterId].forEach(el => {
                _.setWith(
                    updatedFilterVal,
                    [el.id],
                    {
                        id: el.id,
                        option: el.name,
                        selected: Boolean(selectedHmap[el.id]),
                    },
                    Object
                )
            })
            this.filters = Object.assign({}, this.filters, { [filterId]: updatedFilterVal })
        },
        async initData() {
            this.dashboardDataLoading = true
            const { data, error } = await getReturnabilityData({
                projectId: this.project.id,
                filters: this.queryFilters,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.dashboardData = _.omit(data, 'filters')
            this.filters = data.filters
            this.dashboardDataLoading = false
        },
    },
    async mounted() {
        this.pageLoading = true
        this.projectType = await api.project
            .getType({ projectId: this.project.id })
            .then(res => res.data?.type)
        await this.setDefaultDateBorders()
        await this.initData()
        this.pageLoading = false
    },
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
.deleted
    color: red
.contact
    color: #1d9e00
.changed
    color: #fbbc04
.filters
    display: flex
    flex-wrap: wrap
    gap: 16px
.filter
    min-width: 200px
::v-deep .recomendations-table thead th
    background-color: #0000000B !important
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content
    box-shadow: none
</style>
