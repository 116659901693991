import { render, staticRenderFns } from "./ReturnabilityDashboard.vue?vue&type=template&id=3236100b&scoped=true"
import script from "./ReturnabilityDashboard.vue?vue&type=script&lang=js"
export * from "./ReturnabilityDashboard.vue?vue&type=script&lang=js"
import style0 from "./ReturnabilityDashboard.vue?vue&type=style&index=0&id=3236100b&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3236100b",
  null
  
)

export default component.exports